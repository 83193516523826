<template>
  <b-button @click="downloadPdf()" v-bind="$attrs">
    <slot></slot>
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";
import FileSaver from "file-saver";

export default {
  components: {
    BButton,
  },
  props: {
    fileName: {
      type: String,
      default: "File",
    },
    resource: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: null,
    },
    repository: {},
  },
  data() {
    return {};
  },
  methods: {
    downloadPdf() {
      if (this.repository) {
        this.$showLoading();
        this.repository
          .downloadPdf(this.id, this.handleDownloadProgress)
          .then((response) => {
            this.loading = false;
            const fileName = `${this.fileName}.pdf`;
            FileSaver.saveAs(response.data, fileName);
          })
          .catch(() => {
            this.$hideLoading();
          })
          .then(() => {
            this.$hideLoading();
          });
      }
    },
    handleDownloadProgress(progressEvent) {
      const total = progressEvent?.total || 0;
      const current = progressEvent?.loaded || 0;
      this.$showLoading(current, total, true);
    },
  },
};
</script>